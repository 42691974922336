export default {
  maintenance: 'Sorry! The game is currently in maintenance mode.\n Please try again later.',
  rate: 'Rate: {betAmount}x',
  rtp: 'RTP {amount}%',
  howToPlay: 'How To Play?',
  fairPlayRules: 'Fair Play Rules',
  fairPlay: 'Fair Play',
  limits: 'Limits',
  currentBalance: 'Current Balance: {balance}',
  changeAvatar: 'Change Avatar',
  setYourTime: 'Set Your Time',
  update: 'Update v{version}',
  gameIsStarting: 'Game is starting',
  modal: {
    howToPlay: {
      title: 'How To Play?',
      description: `
        <ul>
          <li>Enter the amount you want to play in the "Bet Amount" field.</li>
          <li>You can multiply the amount you deposited with the buttons here.</li>
          <li>By clicking the "Play Bet" button, you join the game for the next round. You can either cash out at your desired multiplier X or let the system automatically cash out for you.</li>
          <li>The multiplier increase may stop at any time (including 1.00x). If the multiplier stops before clicking the "Cash Out" button, your game is considered lost.</li>
          <li>The system will automatically cash out for you when the multiplier reaches the value you entered in the "Auto Cash Out" field.</li>
          <li>You can successfully complete the game by clicking the "Cash Out" button and the amount you win is the one displayed above the "Cash Out" button.</li>
          <li>For example, a player entering the game by depositing 3 TL, if they cash out at a multiplier of 2.05x before the fish explodes or is exploded by the Fisherman, they will win 3 x 2.05 = 6.15.</li>
          <li>By clicking the "Play Risky Bet" button, in the played game, if the Fisherman manages to explode the fish at X, your winnings will be twice the value of X.</li>
          <li>In the risky bet option, there are no "auto cash out" or "cash out" options.</li>
        </ul>
      `
    },
    chatRules: {
      title: 'Chat Rules',
      description: `
        <ul>
          <li>Approach all chat users with respect. Engaging in communication on the following topics in the chat room may result in the temporary or permanent closure of the account:</li> 
          <li>Discussing topics such as profanity, offensive language, insults, propaganda, politics, or any other topics that may disturb other users.</li> 
          <li>Sharing illegal content.</li> 
          <li>Posting messages with commercial content or advertisements.</li> 
          <li>Distributing inaccurate or misleading information.</li> 
          <li>Engaging in behavior and communication that may cause discomfort to other members is not acceptable.</li> 
          <li>In case a user utilizes profanity, offensive language, or insults three times, they will face a temporary chat ban. Refrain from sharing personal information with unfamiliar individuals. Additionally, do not share others' information with anyone. We have zero tolerance for unauthorized disclosure of personal information (doxxing) on our platform.</li>
        </ul>
      `
    },
    fairPlayRules: {
      title: 'Fair Play Rules',
      description: `
        <ul>
          <li>Before each round, players can determine the game stake amount as desired, subject to game limits.</li>
          <li>After a bet is placed, a fish moving on the screen will ascend until reaching a random multiplier.</li>
          <li>Your winnings will be calculated by multiplying the amount you entered the game with the multiplier shown on the graph. By using the "Auto Exit" feature, you can automatically set the multiplier at which you want to exit the game if the fish surpasses it.</li>
          <li>You can adjust the multiplier you want to win from the "Auto Exit" section automatically.</li>
          <li>The amount you win will be credited to your balance before the next round.</li>
          <li>The multiplier increase can end at any time (including 1.00x). If the multiplier increase ends before clicking the "Exit Bet" button, your game will be considered lost.</li>
          <li>The multiplier amount on the graph will result in the game being concluded with winnings based on the multiplier amount entered in the "Auto Exit" section when it numerically exceeds.</li>
          <li>The maximum amount that can be won in a single bet is X.</li>
          <li>Checking Round Reliability:</li>
          <li>Round control ensures that the coefficient point in the round is predetermined and not dependent on user game stakes or other factors.</li>
          <li>The results of upcoming rounds are predetermined on the server. The game progresses in accordance with this sequence. The outcome of the next round is not determined based on the current round being played. This means that the assumption that the coefficient point of the next round is determined based on the user's behavior is not correct.</li>
          <li>What is the round key and reliability code?</li>
          <li>On the game page, under the "History" tab, completed and currently ongoing rounds are listed. For completed rounds, you can find the round key along with the reliability code. The reliability code is the round key encrypted with the SHA512 algorithm. This encrypted key is unique, and it is not possible for two different keys to have the same encryption code. In other words, players can compare this key with others and see that the round's result cannot be altered without changing the key."</li>
        </ul>
      `
    },
    lastRounds: {
      title: 'Last Rounds'
    },
    userSettings: {
      title: 'Player Settings',
      changeAvatar: 'Change Avatar',
      saveChanges: 'Save Changes'
    },
    roundDetails: {
      title: 'Round Details {rate}x / {time}',
      serverSeed: 'Server Seed:',
      serverSeedDesc: 'Hashed by us',
      clientSeed: 'Client Seed:',
      clientSeedDesc: 'Hashed by the player',
      mergedHash: 'Merged SHA512 Hash:',
      mergedHashDesc:
        'The above seeds are combined and converted to SHA512 hash. This is your game result'
    },
    limits: {
      title: 'Limits',
      minBetAmount: 'Min. Bet Amount:',
      maxBetAmount: 'Max. Bet Amount:'
    },
    betDetails: {
      title: 'Bet Details',
      screenshot: 'Screenshot',
      isRiskyBet: 'Risky Bet:',
      betAmount: 'Bet Amount: {betAmount}',
      winAmount: 'Win Amount: {winAmount}',
      createdAt: 'Created At: {createdAt}',
      updatedAt: 'Update At: {updatedAt}',
      rate: 'Rate:',
      step: 'Step:'
    },
    setYourTime: {
      title: 'Set Your Time (minute)',
      saveChanges: 'Start Timer'
    },
    userTimeOver: {
      title: 'Time is over',
      description:
        'Your set time has ended. Please take a short break to rest and enjoy your game. Have a great day!'
    }
  },
  messages: {
    error: 'Error',
    somethingWentWrong: 'Something went wrong',
    success: 'Success',
    socketConnectionError: 'Something went wrong while connecting socket.io',
    gameJoinSuccess: 'You have successfully joined the game! Your new balance {balance}',
    youWon: 'You won!',
    youLost: 'You have lost the game!',
    congratulations: 'Congratulations!',
    dontBeSad: "Don't be sad!",
    userSettingsChanged: 'User settings successfully changed.',
    cancelBet: 'You have successfully cancelled the game.',
    cancelBetError: 'Something went wrong while canceling the game.',
    underConstruction:
      "We encountered an unexpected problem!\n Don't worry, you'll be able to enjoy the game soon.",
    unsupportedBrowser:
      'Your browser is not supported.\n Please use the latest version of Google Chrome, Mozilla Firefox, Safari, or Microsoft Edge.',
    replayNotFound: 'Game replay not found',
    backend: {
      InsufficientLimit: 'You don`t have enough limits',
      ActiveBetNotFound: 'You have no active game',
      NoRoundReplyRecordFound: 'No round reply record found',
      MaxBetAmountExceeded: 'The game max bet amount exceeded',
      AmountLoverThanMinBetAmount: 'Was below the bet minimum amount',
      MaxAutoTakeItExceeded: 'The game max auto take it value exceeded',
      AmountLoverThanMinAutoTakeItValue: 'Was below the minimum auto take it amount',
      GameNotStarted: 'The game hasn`t started yet',
      ActiveGameRoundNotFoun: 'Active game not found…',
      ActiveTransactionNotFound: 'You have no active game',
      YouPlayWithRisky: 'Playing with Risky, you can`t get out of the game',
      BannedFromTheGame: 'Banned from the game',
      RoundStarted: 'The game has started',
      NotAuthenticated: "User couldn't authenticate!",
      PlayerSessionAlreadyExists: "User's session already exists!",
      IntegrationError: 'Integration error',
      ActiveGameRoundNotFound: 'Active game not found',
      ActiveGameRoundAlreadyExists: 'Active game round already exists'
    }
  },
  newDesign: {
    bet: 'Bet',
    next: 'Next',
    risky: 'Risky',
    cancel: 'Cancel',
    cancelNext: 'Cancel',
    close: 'Close',
    save: 'Save',
    cashoutWithAmount: 'Cashout {amount}',
    rtp: 'RTP {amount}%',
    autoTake: 'Auto Take',
    gameSound: 'Game Sound',
    empty: 'Empty',
    noContent: 'There is nothing to show.',
    typeMessage: 'Type message...',
    greaterThan: 'Greater Than',
    lessThan: 'Less Than',
    setYourTimeDescription: 'You can set yourself playtime to manage your addictions.',
    betAmount: 'Bet Amount',
    viewScreenshot: 'View Screenshot',
    updated: 'Updated',
    rate: 'Rate',
    win: 'Win',
    lose: 'Lose',
    new: 'New',
    clientSeed: 'Client Seed',
    hashedByPlayer: 'Hashed By Player',
    player: 'Player',
    seed: 'Seed',
    mergedSHAS512Hash: 'Merged SHAS512 Hash',
    code: 'Code',
    yes: 'Yes',
    no: 'No',
    minuteShort: 'Min',
    chat: 'Chat',
    changeAvatar: 'Change Avatar',
    setTime: 'Set Time',
    settings: 'Settings',
    staticPage: 'Static Page',
    betDetail: 'Bet Detail',
    myBets: 'My Bets',
    players: 'Players',
    play: 'Play',
    howToPlay: 'How To Play?',
    fairPlayRules: 'Fair Play Rules',
    limits: 'Limits',
    chatRules: 'Chat Rules',
    navigation: 'Navigation',
    lastRounds: 'Last Rounds',
    roundDetail: 'Round Detail',
    whatIsRisky: 'What is Risky Bet?',
    gameRules: 'Game Rules',
    copy: 'Copy',
    copied: 'Copied!',
    loadMore: 'Load More',
    messages: {
      avatarSuccessfullyChanged: 'Avatar changed successfully',
      timeSuccessfullySet: 'Time successfully set'
    },
    generateGameReplayLink: 'Game Replay Link',
    loadingReplayLink: 'Generating Game Replay Link...',
    watchReplay: 'Watch Game Replay',
    replayLinkUnavailable: 'Game Replay Link Unavailable',
    link: 'Link',
    limitModalDescription: 'Max. and Min. Bet Amounts for this game.',
    min: 'Min:',
    max: 'Max:',
    you: 'You',
    originalRate: 'Original Rate',
    reload: 'Reload',
    connectionProblem: 'Connection Problem. Please refresh the page.',
    amountOutOfRange: 'Please set an amount in range of {min} - {max}',
    messageSending: 'Message Sending...',
  }
}
