<script lang="ts" setup>
import { useElementBounding } from '@vueuse/core'
import { nextTick, onBeforeUnmount, onMounted, reactive, ref, type Ref } from 'vue'

const props = withDefaults(defineProps<{
  text?: string
  position?: 'top' | 'bottom' | 'left' | 'right'
  parentElem?: Ref<HTMLElement>
}>(), {
  text: '',
  position: 'top'
})

const tooltipElem = ref<HTMLElement | null>(null)
const tooltipContentElem = ref<HTMLElement | null>(null)
const styles = reactive<{
  left: string | number
  top: string | number
  bottom: string | number
  right: string | number
}>({
  left: 'initial',
  top: 'initial',
  bottom: 'initial',
  right: 'initial',
})

const calcPosition = () => {
  const { left, width, top, bottom, height, right } = useElementBounding(props.parentElem || null)

  if (tooltipContentElem.value) {
    const tooltipWidth = tooltipContentElem.value.offsetWidth
    const tooltipHeight = tooltipContentElem.value.offsetHeight

    switch (props.position) {
      case 'top':
        styles.left = `${left.value + width.value / 2 - tooltipWidth / 2}px`
        styles.top = `${top.value - tooltipHeight - 10}px`
        break
      case 'bottom':
        styles.left = `${left.value + width.value / 2 - tooltipWidth / 2}px`
        styles.top = `${bottom.value + 10}px`
        break
      case 'left':
        styles.left = `${left.value - tooltipWidth - 10}px`
        styles.top = `${top.value + height.value / 2 - tooltipHeight / 2}px`
        break
      case 'right':
        styles.left = `${right.value + 10}px`
        styles.top = `${top.value + height.value / 2 - tooltipHeight / 2}px`
        break
    }
  }
}

onMounted(() => {
  nextTick(() => {
    if (!tooltipElem.value) return

    calcPosition()
    document.body.appendChild(tooltipElem.value)
  })

  document.addEventListener('click', (e) => {
    if (!tooltipElem.value) return

    if (!tooltipElem.value.contains(e.target as Node)) {
      calcPosition()
    }
  })

  window.addEventListener('resize', () => {
    calcPosition()
  })
})

onBeforeUnmount(() => {
  if (!tooltipElem.value) return

  document.body.removeChild(tooltipElem.value)
})
</script>

<template>
  <div class="balkan-tooltip" ref="tooltipElem">
    <div class="balkan-tooltip__wrapper" :class="`balkan-tooltip__wrapper--${position}`" ref="tooltipContentElem"
      :style="styles">
      <div class="balkan-tooltip__content">
        {{ text }}
      </div>
    </div>
  </div>
</template>
