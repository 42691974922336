export default {
  maintenance: 'Üzgünüz! Oyun şu anda bakım modunda.\n Lütfen daha sonra tekrar deneyin.',
  rate: 'Oran: {betAmount}x',
  rtp: 'RTP {amount}%',
  howToPlay: 'Nasıl Oynanır?',
  fairPlay: 'Adil Oyun',
  fairPlayRules: 'Adil Oyun Kuralları',
  limits: 'Limitler',
  currentBalance: 'Bakiye: {balance}',
  changeAvatar: 'Avatar Değiştir',
  setYourTime: 'Zaman Ayarla',
  update: 'Güncelleme v{version}',
  gameIsStarting: 'Oyun başlıyor',
  modal: {
    howToPlay: {
      title: 'Nasıl Oynanır?',
      description: `
        <ul>
          <li>“Bahis Miktarı” alanına oynamak istediğiniz miktarı giriniz.</li>
          <li>Buradaki butonlarla yatırdığınız tutarınızı katlayabilirsiniz.</li>
          <li>“Bahis Oyna” butonuna tıklayarak sıradaki tur için oyuna katılmış olursunuz. Bahisten istediğiniz X çarpanında siz çıkabilirsiniz ya da otomatik çıkış seçeneği ile sistem sizin için otomatik olarak çıkış sağlar.</li>
          <li>Çarpan artışı herhangi bir zamanda durabilir (1.00x dahil). Eğer “Bahisten Çık” butonuna tıklamadan önce çarpan artışı sonlanırsa, oyununuz kaybetmiş olarak kabul edilir.</li>
          <li>“Otomatik Çıkış” alanına girdiğiniz değer sayesinde sistem sizin için otomatik olarak ilgili X değerine gelince bahisten çıkar.</li>
          <li>“Bahisten Çık”  butonuna tıklayarak kazançlı şekilde oyunu tamamlayabilirsiniz. Kazandığınız tutar “Bahisten Çık”  butonunun üstünde yazan tutardır.</li>
          <li>Örneğin; oyuna 3 TL para yatırarak giren bir oyuncu, FishTrap’te balık patlamadan ya da Fisherman tarafından patlatılmadan önce 2.05x katsayısında çekilirse 3x2.05=6.15 değerinde kazanmış olur.</li>
          <li>Riskli Bahis Oyna”  butonuna tıklayarak oynanan oyunda Fisherman, balığı kaç X’te vurmayı başarırsa o X değerinin iki katı kazancınız olur.</li>
          <li>Riskli bahis seçeneğinde “otomatik çıkış” ya da “bahisten çık” seçenekleri yoktur.</li>
        </ul>
      `
    },
    chatRules: {
      title: 'Chat Kuralları',
      description: `
        <ul>
          <li>Tüm sohbet kullanıcılarına saygıyla yaklaşın. Sohbet odasında aşağıdaki konularda iletişim kurmak, hesabın geçici veya kalıcı olarak kapatılmasına neden olabilir:</li>
          <li>Küfürlü dil, saldırgan ifadeler, hakaret, propaganda, siyaset veya diğer kullanıcıları rahatsız edebilecek konuların tartışılması.</li>
          <li>Yasadışı içerik paylaşımı.</li>
          <li>Ticari içerik veya reklam içerikli mesajlar yazma.</li>
          <li>Yanlış veya yanıltıcı bilgilerin dağıtılması.</li>
          <li>Diğer üyeleri rahatsız edici davranış ve iletişimde bulunmak kabul edilemez.</li>
          <li>Eğer bir kullanıcı üç kez küfürlü dil, saldırgan ifadeler veya hakaret kullanırsa, geçici bir sohbet yasağıyla karşılaşacaktır. Kişisel bilgilerinizi yabancılarla paylaşmaktan kaçının. Ayrıca, başkalarının bilgilerini kimseyle paylaşmayın. Platformumuzda kişisel bilgilerin izinsiz açıklanmasına (doxxing) kesinlikle tolerans göstermiyoruz.</li>
        </ul>
      `
    },
    fairPlayRules: {
      title: 'Adil Oyun Kuralları',
      description: `
        <ul>
          <li>Her tur öncesinde oyuncu dilediği miktarda oyun bedelini oyun limitlerine bağlı olarak belirleyebilir.</li>
          <li>Bahis alındıktan sonra, ekranda hareket eden balık, rastgele bir çarpana kadar yükselecektir.</li>
          <li>Kazancınız oyuna katıldığınız bedelin, grafik üzerinde gösterilen çarpan ile çarpılması sonucunda hesaplanacaktır.</li>
          <li>“Otomatik Çıkış” özelliğini kullanarak, balığın belirlediğiniz çarpanı geçmesi halinde otomatik olarak kazançla ayrılmak istediğiniz çarpanı belirleyebilirsiniz.</li>
          <li>Otomatik Çıkış kısmından kazanmak istediğiniz çarpan miktarını otomatik olarak ayarlayabilirsiniz.</li>
          <li>Kazandığınız miktar bir sonraki tur öncesinde bakiyenize yansıtılacaktır.</li>
          <li>Çarpan artışı herhangi bir zamanda sonlanabilir (1.00x dahil). Eğer “Bahisten Çık” butonuna tıklamadan önce çarpan artışı sonlanırsa, oyununuz kaybetmiş olarak kabul edilir.</li>
          <li>Grafik üzerindeki çarpan miktarı, “Otomatik Çıkış” alanına girilen çarpan miktarını sayısal olarak geçtiği anda oyununuz, “Otomatik Çıkış” alanına girilen çarpan miktarı üzerinden kazanç ile sonuçlandırılır.</li>
          <li>Tek bir bahiste kazanılabilecek en yüksek miktar X’tir.</li>
          <li>Turun Güvenilirliğini Kontrol Etme:</li>
          <li>Tur kontrolü, turdaki katsayı noktasının önceden tanımlanmış olduğundan ve kullanıcıların oyun bedellerine veya diğer faktörlere bağlı olmadığından emin olmanızı sağlar.</li>
          <li>Yaklaşmakta olan turların sonuçları sunucuda daha önceden belirlenmiştir. Oyunun seyri de bu sıraya göre ilerler. Sıradaki turun sonucu oynanmakta olan tura göre belirlenmez. Bu da demektir ki; bir sonraki turun katsayı noktasının kullanıcının davranışına göre belirlendiği varsayımı doğru değildir.</li>
          <li>Tur anahtarı ve güvenilirlik kodu nedir?</li>
        </ul>
      `
    },
    lastRounds: {
      title: 'Son Turlar'
    },
    userSettings: {
      title: 'Profil Ayarları',
      changeAvatar: 'Avatarı Değiştir',
      saveChanges: 'Değişiklikleri Kaydet'
    },
    roundDetails: {
      title: 'Tur Detayları {rate}x / {time}',
      serverSeed: 'Sunucu Seed:',
      serverSeedDesc: 'Bizim tarafimizdan olusturuldu',
      clientSeed: 'İstemci Seed:',
      clientSeedDesc: 'Oyuncu tarafindan olusturuldu',
      mergedHash: 'Birleştirilmiş SHA512 Hash:',
      mergedHashDesc:
        "Yukaridaki seed'ler birlestirilmis ve SHA512 Hash'e dönüstürülmüstür. Bu sizin oyun sonucunuz"
    },
    limits: {
      title: 'Limitler',
      minBetAmount: 'En az bahis tutarı:',
      maxBetAmount: 'En çok bahis tutarı:'
    },
    betDetails: {
      title: 'Bahis Detayı',
      screenshot: 'Ekran Görüntüsü',
      isRiskyBet: 'Riskli Bahis:',
      betAmount: 'Bahis Tutarı: {betAmount}',
      winAmount: 'Kazanılan Tutar: {winAmount}',
      createdAt: 'Oluşturulma Tarihi: {createdAt}',
      updatedAt: 'Güncellenme Tarihi: {updatedAt}',
      currency: 'Para Birimi:',
      rate: 'Oran:',
      step: 'Adım:'
    },
    setYourTime: {
      title: 'Zaman Seç (dakika)',
      saveChanges: 'Başlat'
    },
    userTimeOver: {
      title: 'Oyun süreniz doldu',
      description:
        'Belirlediğiniz süre doldu. Lütfen dinlenmek için biraz ara verin ve oyununuzun tadını çıkarın. İyi bir gün dileriz!'
    }
  },
  messages: {
    error: 'Hata',
    somethingWentWrong: 'Bir şeyler ters gitti',
    success: 'Başarılı',
    socketConnectionError: "Socket.io'ya bağlanırken hata oluştu",
    gameJoinSuccess: 'Oyuna başarıyla katıldın. Yeni bakiyen {balance}',
    youWon: 'Kazandın!',
    youLost: 'Oyunu kaybettin!',
    congratulations: 'Tebrikler!',
    dontBeSad: 'Üzülme...',
    userSettingsChanged: 'Kullanıcı ayarları başarıyla değiştirildi.',
    cancelBet: 'Başarıyla oyundan çıktınız.',
    cancelBetError: 'Oyundan çıkılırken bir hata oluştu.',
    underConstruction:
      'Beklenmedik bir problemle karşılaştık!\n Merak etme, yakında oyun zevkine geri dönebileceksin.',
    unsupportedBrowser:
      "Tarayıcınız desteklenmiyor.\n Lütfen Google Chrome, Mozilla Firefox, Safari veya Microsoft Edge'in en son sürümünü kullanın.",
    replayNotFound: 'Oyun tekrarı bulunamadı',
    backend: {
      InsufficientLimit: 'Limit yeterli değil',
      ActiveBetNotFound: 'Hiç aktif oyununuz bulunmamaktadır',
      NoRoundReplyRecordFound: 'Oyun tekrarı bulunamadı',
      MaxBetAmountExceeded: 'Max. oynama aralığını aştınız',
      AmountLoverThanMinBetAmount: 'Min. oynama aralığından düşük girdiniz',
      MaxAutoTakeItExceeded: 'Max. otomatik çıkış aralığını aştınız',
      AmountLoverThanMinAutoTakeItValue: 'Min. otomatik çıkış aralığından düşük girdiniz',
      GameNotStarted: 'Oyun henüz başlamadı',
      ActiveGameRoundNotFoun: 'Aktif oyun bulunamadı...',
      ActiveTransactionNotFound: 'Aktif oyununuz bulunmamaktadır',
      YouPlayWithRisky: 'Riskli bahse gidiniz ve bu nedenle oyundan ayrılamazsınız',
      BannedFromTheGame: 'Oyundan banlandınız',
      RoundStarted: 'Oyun başladı',
      NotAuthenticated: 'Kullanıcı giriş yapmamış!',
      PlayerSessionAlreadyExists: 'Kullanıcının oturumu zaten var!',
      IntegrationError: 'Entegrasyon hatası',
      ActiveGameRoundNotFound: 'Aktif oyun bulunamadı',
      ActiveGameRoundAlreadyExists: 'Aktif oyun mevcut.'
    }
  },
  newDesign: {
    bet: 'Oyna',
    next: 'Sıradaki',
    risky: 'Riskli',
    cancel: 'İptal Et',
    cancelNext: 'İptal Et Sıradaki',
    close: 'Kapat',
    save: 'Kaydet',
    cashoutWithAmount: 'Çıkış {amount}',
    rtp: 'RTP {amount}%',
    autoTake: 'Oto Çıkış',
    gameSound: 'Oyun Sesi',
    empty: 'İçerik Yok',
    noContent: 'Şu anda bu alanda gösterilecek bir içerik yok.',
    typeMessage: 'Bir şeyler yaz...',
    greaterThan: 'Büyük',
    lessThan: 'Küçük',
    setYourTimeDescription: 'Bağımlılığını kontrol etmek için bu alandan zamanlama seçebilirsin.',
    betAmount: 'Bahis Miktarı',
    viewScreenshot: 'Ekran Görüntüsü',
    updated: 'Güncellendi',
    rate: 'Oran',
    win: 'Kazandı',
    lose: 'Kaybetti',
    new: 'Yeni',
    clientSeed: 'Client Seed',
    hashedByPlayer: 'Kullanıcı tarafından oluşturulan',
    player: 'Oyuncu',
    seed: 'Seed',
    mergedSHAS512Hash: 'Merged SHAS512 Hash',
    code: 'Kod',
    yes: 'Evet',
    no: 'Hayır',
    minuteShort: 'Dk.',
    chat: 'Sohbet',
    changeAvatar: 'Avatar Değiştir',
    setTime: 'Zaman Belirle',
    settings: 'Ayarlar',
    staticPage: 'Statik Sayfa',
    betDetail: 'Bahis Detayı',
    myBets: 'Bahislerim',
    players: 'Oyuncular',
    play: 'Oyna',
    howToPlay: 'Nasıl Oynanır?',
    fairPlayRules: 'Adil Oyun Kuralları',
    limits: 'Limitler',
    chatRules: 'Sohbet Kuralları',
    navigation: 'Menü',
    lastRounds: 'Son Turlar',
    roundDetail: 'Tur Detayı',
    whatIsRisky: 'Riskli Bahis Nedir?',
    gameRules: 'Oyun Kuralları',
    copy: 'Kopyala',
    copied: 'Kopyalandı!',
    loadMore: 'Daha Fazla Yükle',
    messages: {
      avatarSuccessfullyChanged: 'Avatar başarıyla değiştirildi',
      timeSuccessfullySet: 'Zaman belirlemesi başarılı',
    },
    generateGameReplayLink: 'Oyun Kaydı Linki',
    loadingReplayLink: 'Oyun Kaydı Linki Oluşturuluyor...',
    watchReplay: 'Oyun Kaydını İzle',
    replayLinkUnavailable: 'Oyun Kaydı Linki Yok',
    link: 'Link',
    limitModalDescription: 'Bu oyun için minimum ve maksimum bahis limitleri.',
    min: 'Min:',
    max: 'Max:',
    you: 'Sen',
    originalRate: 'Orijinal Oran',
    reload: 'Yeniden Yükle',
    connectionProblem: 'Bağlantı Sorunu. Lütfen sayfayı yenileyin.',
    amountOutOfRange: 'Lütfen {min} - {max} arasında bir değer girin.',
    messageSending: 'Mesaj gönderiliyor...',
  }
}
